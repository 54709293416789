import React from 'react'
import {graphql} from 'gatsby'
import Typography from '@material-ui/core/Typography'
import {mapEdgesToNodes} from '../lib/helpers'
import BlogPostPreviewGrid from '../components/blog-post-preview-grid'
import Box from '@material-ui/core/Box'
import Img from 'gatsby-image'
import Wrapper from '../components/wrapper'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
    description
    keywords
    heroImage {
      asset {
        fluid {
          ...GatsbySanityImageFluid_withWebp_noBase64
        }
      }
    }
  }
   posts: allSanityPost(
      limit: 4
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const site = (data || {}).site
  const postNodes = data && data.posts && mapEdgesToNodes(data.posts)
  return (
    <Layout>
      <SEO
        title='A Developer and Educator'
        description={site.description}
        keywords={site.keywords} />
      
        <Box
        style={{color:'#fff', textShadow: '1px 2px 6px #000000'}}
        textAlign='center'
        p={2}
        width='100%'
        position="absolute"
        top='25%'
        zIndex="mobileStepper"
      >
      <Typography variant='h4'> A Portfolio and Blog</Typography> 
      <Typography variant='h4'> about learning</Typography>
      <Typography> from Edwin Bartunek</Typography>
      </Box>
       
       <Img
       style={{height: '450px'}}
       loading={`lazy`}
       fluid={site.heroImage.asset.fluid}
       backgroundColor={`#102514`}
     />
       
     
      <Wrapper>
        {postNodes && postNodes.length > 0 && <BlogPostPreviewGrid title='Latest blog posts' nodes={postNodes} />}
      </Wrapper>
    </Layout>
  )
}

export default IndexPage
